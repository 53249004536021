import { Component } from "../lib/_component";
import ComponentType from "../lib/_dom-events.t";
import Events from "./filter.events"
require("../lib/pkg/datepicker")
export class DatePicker extends Component implements ComponentType {
    name = 'DatePicker';

    onDomReady() {
        this.init();
    }
    init() {
        this.elementRef = '#aui-datepicker';
        this.element = $(this.elementRef);
        this.element.datepicker({
            markup:'bootstrap4',
            firstDayOfWeek: 1, // 0 sunday, 1 monday 
            outputFormat: 'M/d/yyyy', // d/M/yyyy , yyyy/M/d
            weekDayFormat:'short', // narrow
            startView: 0, //0 days, 1 months, 2 years
        });
    }

}